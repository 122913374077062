<template>
  <div class="v-projects-show-services-index-map"></div>
</template>

<script>
export default {
  name: 'VProjectsShowServicesIndexMap',
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
